import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'projects/loki-desktop/src/environments/environment';
import { EmailMessageQuery, EmailStatus, MessageStatus, NotificationMessageQuery, Push, PushRequestMapper, PushResponseMapper } from './models/dtos/email-message.query';
import { Mapper } from './models/mapper';
import { map } from 'rxjs/operators';
import { PaginatedData } from './models/pagination';
import { ApiResponse } from './models/dtos/api-response.dto';
import { JavaQuery, Query } from './models/query';

@Injectable()
export class NotificationDataService {
  private readonly url: string = `${environment.api.gatewayURL}/notification/api/v1/notifications`;

  constructor(
    private http: HttpClient
  ) {
  }

  public sendEmail(query: EmailMessageQuery): Observable<MessageStatus> {
    const payload = Mapper.mapModel(new NotificationMessageQuery(query));
    return this.http.post<MessageStatus>(`${this.url}/mail/sync`, payload);
  }

  public sendPush(push: Omit<Push, 'date'>): Observable<Push> {
    const payload = Mapper.mapModel(new PushRequestMapper(push));
    return this.http.post<Push>(`${this.url}/push/sync`, payload)
      .pipe(
        map(res => Mapper.mapResponse(res, PushResponseMapper))
      );
  }

  public status(operationId: string): Observable<EmailStatus[]> {
    return this.http.get<EmailStatus[]>(`${this.url}/messenger/${operationId}/logs`);
  }

  public pushNotifications(applicationId: string, query: Query): Observable<PaginatedData<Push>> {
    const params = Mapper.mapModel(new JavaQuery(query));
    return this.http.get<ApiResponse<Push>>(`${this.url}/push/push-app-code/${applicationId}`, { params })
      .pipe(
        map(res => Mapper.mapPaginatedResponse(res, PushResponseMapper))
      );
  }
}
