<div class="app-error-page">
  <img src="assets/img/error_404@3x.png"
       alt="desktop screen with a warning sign"
       class="app-error-image"
       width="0">
  <div class="app-error-header-container">
    <h1 class="app-error-header">{{ 'ERROR_PAGE.NOT_FOUND_HEADER' | translate }}</h1>
    <button type="button"
            class="button-wide button-green app-error-page-back-button"
            (click)="goToMainPage()">
      {{ 'ERROR_PAGE.MAIN_SCREEN_BUTTON' | translate }}
    </button>
  </div>
</div>
