import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../loki-desktop/src/environments/environment';
import { Observable } from 'rxjs';
import { ResetPasswordDto } from './models/dtos/reset-password.dto';
import { ForgotPasswordDto } from './models/dtos/forgot-password.dto';
import { Profile, Resource, User } from './models/user';
import { ChangePasswordDto } from './models/dtos/change-password.dto';

@Injectable()
export class UserDataService {
  private readonly url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = `${environment.api.gatewayURL}/ums/public/api/v1`;
  }

  public forgotPassword(recovery: ForgotPasswordDto): Observable<void> {
    return this.http.post<void>(`${this.url}/password/send-token`, null, { params: { ...recovery } });
  }

  public resetPassword(credentials: ResetPasswordDto): Observable<void> {
    return this.http.put<void>(`${this.url}/password/recover`, credentials);
  }

  public changePassword(data: ChangePasswordDto): Observable<void> {
    return this.http.put<void>(`${this.url}/password/change`, data);
  }

  public profile(): Observable<User> {
    return this.http.get<User>(`${this.url}/users/info`);
  }

  public changeProfile(userId: string, profile: Profile): Observable<User> {
    return this.http.put<User>(`${this.url}/users/${userId}/info`, profile);
  }

  public resources(): Observable<Resource[]> {
    return this.http.get<Resource[]>(`${this.url}/user-resources/users`);
  }
}
