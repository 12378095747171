import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { fromEvent } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Dispose } from "../../components/dispose";
import { GoogleAnalyticsService } from "./google-analytics.service";

@Directive({ selector: "[analyticsEvent]" })
export class AnalyticsEventDirective extends Dispose implements OnInit {
    @Input("analyticsEvent") eventName!: string;
    @Input("analyticsEventData") eventData = {};
    @Input("analyticsEventTrigger") eventTrigger = "click";

    constructor(private analyticsService: GoogleAnalyticsService, private element: ElementRef) {
        super();
    }

    ngOnInit(): void {
        fromEvent(this.element.nativeElement, this.eventTrigger)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.analyticsService.trackEvent(this.eventName, this.eventData);
            });
    }
}
