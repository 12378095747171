import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';
import { Observable, zip } from 'rxjs';
import { AppService } from '../services/app.service';
import { Role } from 'projects/global-shared/shared/api/models/enums/role.enum';

/**
 * @description
 * Guard that continues navigation based on certain business rules.
 * If the navigation stops at the app root url (e.g. open the domain url)
 * will start a new navigation to the resource endpoint according to user rights
 */
@Injectable()
export class NormalizeRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private app: AppService,
    private user: UserService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree> | boolean {
    if (state.url !== '/') {
      return true;
    }

    return this.app.load(
        zip(this.user.current$, this.user.currentRole())
      )
      .pipe(
        map(([user, role]) => {
          switch (role) {
            case Role.ADMIN:
              return this.router.parseUrl('/customers');
            case Role.ACCOUNTANT:
              return this.router.parseUrl('/payout');
            default:
              return this.router.parseUrl(`/${user.resources[0].branchId}/dashboard`);
          }
        })
      );
  }
}
