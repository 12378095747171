<div class="loader"
     [class.loader-transparent]="transparent"
     [class.loader-cover]="cover">
  <p *ngIf="error | async as err; else load">{{ err.message }}</p>
  <ng-template #load>
    <div *ngIf="text"
         class="loader-text">{{ text }}</div>
    <img [src]="color === 'white' ? 'assets/img/loki_anim_white.svg' : 'assets/img/loki_anim.svg'"
         alt=""
         class="loader-img"
         [class.loader-img-large]="large">
  </ng-template>
</div>
