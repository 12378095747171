import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'projects/loki-desktop/src/environments/environment';
import { PaginatedData } from './models/pagination';
import { Customer, CustomerQuery } from './models/customer';
import { map } from 'rxjs/operators';
import { Mapper } from './models/mapper';
import { ApiResponse } from './models/dtos/api-response.dto';

@Injectable()
export class CustomersDataService {
  private readonly url: string = `${environment.api.gatewayURL}/shopsystem/public/api/v1/loki/filter`;

  constructor(
    private http: HttpClient
  ) {}

  public list(query: CustomerQuery): Observable<PaginatedData<Customer>> {
    const params = query && Mapper.mapModel(new CustomerQuery(query));
    return this.http.get<ApiResponse<Customer>>(this.url, { params })
      .pipe(
        map(res => Mapper.mapPaginatedResponse(res, Customer))
      );
  }
}
