import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

/**
 * @description
 * Keycloak service adapter
 */
@Injectable()
export class AuthService {
  constructor(
    private keycloak: KeycloakService
  ) {
  }

  public login(): void {
    this.keycloak.login({ redirectUri: window.location.origin });
  }

  public logout(): void {
    this.keycloak.logout();
  }
}
