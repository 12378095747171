import { Location } from '@angular/common';
import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive()
export class ErrorPageDirective implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router
  ) {}

  public ngOnInit(): void {
    const { sourceUrl } = this.activatedRoute.snapshot.queryParams;

    if (sourceUrl) {
      this.location.replaceState(sourceUrl);
    }
  }

  public goToMainPage(): void {
    this.router.navigate(['/']);
  }
}
