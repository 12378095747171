import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { IEnvironment } from "projects/global-shared/core/models/environment.model";

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    public setupAnalytics(environment: IEnvironment): void {
        this.appendAnalyticsScript(environment);
    }

    public trackEvent(eventName: string, eventData = {}): void {
        if (!eventName) throw new Error("GoogleAnalyticsService eventName is not provided");

        gtag("event", eventName, eventData);
    }

    private appendAnalyticsScript(environment: IEnvironment): void {
        const scriptSrc = environment?.analytics?.google?.scriptSrc;
        const measurementId = environment?.analytics?.google.measurementId;

        if (!scriptSrc || !measurementId) {
            throw new Error("Google Analytics is not configured for this environment");
        }

        const googleAnalyticsScript = this.document.createElement("script");
        googleAnalyticsScript.async = true;
        googleAnalyticsScript.src = `${scriptSrc}${measurementId}`;
        this.document.head.prepend(googleAnalyticsScript);

        gtag("config", measurementId);
    }
}
