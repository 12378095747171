import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private app: AppService,
    private user: UserService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.app.load(this.user.hasRole(route.data.role))
      .pipe(
        tap(allowed => !allowed && this.router.navigate(['forbidden'], {
          skipLocationChange: true,
          queryParams: {
            sourceUrl: state.url
          }
        }))
      );
  }
}
