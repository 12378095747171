import { classToPlain, plainToClass, plainToClassFromExist, TransformFnParams } from 'class-transformer';
import { DateTime } from 'luxon';
import { Model } from './query';
import { isJavaResponse, isPhpResponse, JavaPaginatedData, PaginatedData, PaginationMapper, PhpPaginatedData } from './pagination';
import { Type } from '@angular/core';
import { ApiResponse } from './dtos/api-response.dto';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export class Mapper {
  public static dateTransform(params: TransformFnParams): string | undefined {
    return (params.value as DateTime | undefined)?.toISODate();
  }

  public static timeTransform(params: TransformFnParams): string | undefined {
    return (params.value as DateTime | undefined)?.toISOTime({
      includeOffset: false,
      suppressMilliseconds: true,
      suppressSeconds: true,
    });
  }

  public static dateParse(params: TransformFnParams): DateTime {
    return DateTime.fromISO(params.value);
  }

  public static mapPaginatedResponse<T>(res: ApiResponse<T>, type?: Type<T>, omitProps?: boolean): PaginatedData<T> {
    const opts = { excludeExtraneousValues: type && (omitProps ?? true) };
    if (isPhpResponse(res)) {
      return plainToClassFromExist(new PhpPaginatedData<T>(type), res, opts);
    }
    if (isJavaResponse(res)) {
      return plainToClassFromExist(new JavaPaginatedData<T>(type), res, opts);
    }

    throw new Error('unknown source of response');
  }

  public static mapRandomResponse<R extends PaginationMapper<any>>(res: any, type: R, omitProps?: boolean): R {
    const opts = { excludeExtraneousValues: omitProps ?? true };
    return plainToClassFromExist(type, res, opts);
  }

  public static mapResponse<T>(res: T, type: Type<T>, omitProps?: boolean): T {
    const opts = { excludeExtraneousValues: omitProps ?? true };
    return plainToClass(type, res, opts);
  }

  public static mapModel(model: Model): Record<string, any> {
    return this.omitEmpty(classToPlain(model, { excludeExtraneousValues: true }));
  }

  public static omitEmpty(obj: any): Record<string, any> {
    Object.keys(obj).forEach(k => obj[k] === undefined && delete obj[k]);
    return obj;
  }

  public static toFormData(obj: any): FormData {
    const data = new FormData();
    Object.entries(obj).forEach(([k, v]) =>
      v instanceof File ? data.append(k, v, v.name) : data.append(k, v as any));
    return data;
  }
}

export function mapResponse<T>(type?: Type<T>, omitProps?: boolean): OperatorFunction<ApiResponse<T>, PaginatedData<T>> {
  return source => source
    .pipe(
      map(res => {
        const opts = { excludeExtraneousValues: type && (omitProps ?? true) };
        if (isPhpResponse(res)) {
          return plainToClassFromExist(new PhpPaginatedData<T>(type), res, opts);
        }
        if (isJavaResponse(res)) {
          return plainToClassFromExist(new JavaPaginatedData<T>(type), res, opts);
        }

        throw new Error('unknown source of response');
      })
    );
}
