import { Expose, Type } from 'class-transformer';
import { Branch } from './branch';
import { JavaQuery } from './query';

export class Customer {
  @Expose() externalId!: string;
  @Expose() legacyId!: string;
  @Expose() shopSystemName!: string;
  @Expose() token!: string;
  @Expose() customerNumber!: string;
  @Expose() logoUrl!: string;
  @Expose() @Type(() => Branch) branches!: Branch[];
}

export class CustomerQuery extends JavaQuery {}
