import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { Mapper } from './mapper';
import { Model, Query } from './query';
import { Period } from './filters';

export interface ShopStatus {
  closed: boolean;
  reason: CloseReason;
}

export class OpeningTimes extends Model {
  @Expose()
  @Transform(p => DateTime.local().startOf('week').weekdayShort === 'Sun'
    ? p.value : p.value ? p.value - 1 : 6, { toClassOnly: true })
  @Transform(p => DateTime.local().startOf('week').weekdayShort === 'Sun'
    ? p.value : p.value !== 6 ? p.value + 1 : 0, { toPlainOnly: true })
  public day!: number;

  @Expose()
  @Type(() => TimeInterval)
  @Transform(p => p.value ? p.value : [], { toClassOnly: true })
  @Transform(p => p.value.sort(OpeningTimes.compareTimes), { toPlainOnly: true })
  public delivery!: TimeInterval[];

  @Expose()
  @Type(() => TimeInterval)
  @Transform(p => p.value ? p.value : [], { toClassOnly: true })
  @Transform(p => p.value.sort(OpeningTimes.compareTimes), { toPlainOnly: true })
  public pickup!: TimeInterval[];

  public static compareTimes(a: any, b: any): any {
    return a.start.toMillis() - b.start.toMillis();
  }
}

export class OpeningTimesSettings extends Model {
  @Expose({ name: 'averageDeliveryTime' }) deliveryTime!: number;
  @Expose({ name: 'averageDeliveryTimeEnabled' }) deliveryEnabled!: boolean;
  @Expose({ name: 'averagePreparationTime' }) preparationTime!: number;
  @Expose({ name: 'averagePreparationTimeEnabled' }) preparationEnabled!: boolean;
}

export class SpecialTimesQuery extends Query {
  @Expose() closed?: boolean;
}

interface SpecialTimesModel {
  period: Period;
  closed: boolean;
  openingTimes: Omit<OpeningTimes, 'day'>;
}

export class SpecialTimes extends Model {
  @Expose() closed!: boolean;

  @Expose({ toClassOnly: true })
  @Transform(p =>
    ({ from: DateTime.fromISO(p.obj.startDate), to: DateTime.fromISO(p.obj.endDate) }), { toClassOnly: true })
  public period!: Period;

  @Expose({ toPlainOnly: true })
  @Transform(p => p.value = p.obj.period.from, { toPlainOnly: true })
  @Transform(Mapper.dateTransform, { toPlainOnly: true })
  private startDate!: string;

  @Expose({ toPlainOnly: true })
  @Transform(p => p.value = p.obj.period.to, { toPlainOnly: true })
  @Transform(Mapper.dateTransform, { toPlainOnly: true })
  private endDate!: string;

  @Expose({ toClassOnly: true })
  @Transform(p =>
    ({ delivery: p.obj.delivery, pickup: p.obj.pickup }), { toClassOnly: true })
  @Transform(p => Mapper.mapResponse(p.value, OpeningTimes))
  @Transform(p => delete p.value.day && p.value)
  public openingTimes!: OpeningTimes;

  @Expose({ toPlainOnly: true })
  @Type(() => TimeInterval)
  @Transform(p => p.obj.openingTimes?.delivery, { toPlainOnly: true })
  @Transform(p => p.value ? p.value.sort(OpeningTimes.compareTimes) : [], { toPlainOnly: true })
  private delivery!: TimeInterval[];

  @Expose({ toPlainOnly: true })
  @Type(() => TimeInterval)
  @Transform(p => p.obj.openingTimes?.pickup, { toPlainOnly: true })
  @Transform(p => p.value ? p.value.sort(OpeningTimes.compareTimes) : [], { toPlainOnly: true })
  private pickup!: TimeInterval[];
}

export class TimeInterval {
  @Expose()
  @Transform(Mapper.dateParse, { toClassOnly: true })
  @Transform(p => DateTime.fromISO(p.obj.start) >= p.value
    ? p.value.plus({ day: 1 }) : p.value, { toClassOnly: true })
  @Transform(Mapper.timeTransform, { toPlainOnly: true })
  public end!: DateTime;

  @Expose()
  @Transform(Mapper.dateParse, { toClassOnly: true })
  @Transform(Mapper.timeTransform, { toPlainOnly: true })
  public start!: DateTime;
}

export class OpenBranchResponse {
  @Expose({ name: 'timeType' }) openStrategy!: OpenBranchStrategy;
  @Expose() openingHours!: { start: string, end: string }[] | null;
}

export enum OpenBranchStrategy {
  DEFAULT = 'DEFAULT',
  SCHEDULE = 'SCHEDULE',
  OUT_OF_RANGE = 'OUT_OF_RANGE'
}

export enum CloseReason {
  BRANCH_NOT_EXISTS = 'BRANCH_NOT_EXISTS',
  CLOSED_ON_SCHEDULE = 'CLOSED_ON_SCHEDULE',
  CLOSED_ON_SPECIAL_TIMES = 'CLOSED_ON_SPECIAL_TIMES',
  OPENING_TIMES_NOT_SET = 'OPENING_TIMES_NOT_SET'
}
