import { NgModule } from "@angular/core";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { AnalyticsEventDirective } from "./track-analytics-event.directive";

@NgModule({
    declarations: [AnalyticsEventDirective],
    exports: [AnalyticsEventDirective],
    providers: [GoogleAnalyticsService],
})
export class GoogleAnalyticsModule {}
