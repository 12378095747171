import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'projects/loki-desktop/src/environments/environment';
import { Bill, GroupedBillsResponse } from './models/bill';
import { PhpResponse } from './models/dtos/api-response.dto';
import { map } from 'rxjs/operators';
import { Mapper } from './models/mapper';

@Injectable()
export class BillsDataService {
  private readonly url: string;

  constructor(private http: HttpClient) {
    this.url = `${environment.api.phpURL}/api/v1/loki`;
  }

  public getBills(branchId: string): Observable<PhpResponse<Bill>> {
    return this.http.get<PhpResponse<Bill>>(`${this.url}/bills/${branchId}`);
  }

  public getGroupedBills(branchId: string): Observable<GroupedBillsResponse> {
    return this.http.get<GroupedBillsResponse>(`${this.url}/bills/${branchId}`).pipe(
      map(res => Mapper.mapResponse<GroupedBillsResponse>(res, GroupedBillsResponse))
    );
  }

  public getEmails(branchId: string): Observable<PhpResponse<string>> {
    return this.http.get<PhpResponse<string>>(`${this.url}/bills/emails/${branchId}`);
  }
}
