import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * @description
 * Class simplifying directives (components) disposal
 *
 * The main purpose to dispose rx subscriptions on directive destroy.
 * Commonly used with http observables to cancel them when user navigates to
 * another page (supposed we don't need to display retrieved data anymore).
 * Helpful to complete subscriptions on component destroy.
 *
 * @example
 * Will cancel list request on component dispose
 *
 * ```
 *  this.dataService.list()
 *    .pipe(takeUntil(this.destroy$))
 *    .subscribe()
 * ```
 */
@Directive()
export abstract class Dispose implements OnDestroy {
  /** Subject that emits component destruction */
  protected destroy$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
