<div class="carousel-content">
    <ng-content></ng-content>
</div>
<div class="carousel-dots">
    <span
    class="carousel-dot"
    [class.selected]="currentSlide == i" 
    *ngFor="let item of items; let i = index">
        &#9679;
    </span>
</div>