import { Component } from '@angular/core';
import { AppService } from '../../../../global-shared/core/services/app.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  constructor(
    public app: AppService
  ) {
  }
}
