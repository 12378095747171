import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class RoutingStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const same = super.shouldReuseRoute(future, curr);
    if (same && !curr.children.length) {
      return curr.params.branchId === future.params.branchId;
    }

    return same;
  }
}
