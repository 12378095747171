import { JavaResponse, PhpResponse } from './dtos/api-response.dto';
import { Exclude, Expose, Transform, Type } from 'class-transformer';

export interface Pagination {
  page: number;
  total: number;
  size: number;
}

export interface Data<T> {
  data: T;
}

export interface PaginatedData<T> {
  data: T[];
  pagination: Pagination;
}

export abstract class PaginationMapper<T> {
  @Expose()
  @Type(o => o && (o.newObject as PaginationMapper<T>).type || Object)
  public data!: T[];

  @Exclude()
  private type?: Function;

  constructor(type?: Function) {
    this.type = type;
  }
}

export class PhpPaginatedData<T> extends PaginationMapper<T> implements PaginatedData<T> {
  @Expose()
  @Transform(p => (p.value as Pagination) = {
    page: +(p.obj as PhpResponse<any>).meta.currentPage,
    size: +(p.obj as PhpResponse<any>).meta.perPage,
    total: +(p.obj as PhpResponse<any>).meta.total
  })
  public pagination!: Pagination;
}

export class JavaPaginatedData<T> extends PaginationMapper<T> implements PaginatedData<T> {
  @Expose({ name: 'content' }) data!: T[];

  @Expose()
  @Transform(p => (p.value as Pagination) = {
    page: +(p.obj as JavaResponse<any>).pageable.pageNumber,
    size: +(p.obj as JavaResponse<any>).pageable.pageSize,
    total: +(p.obj as JavaResponse<any>).totalElements
  })
  public pagination!: Pagination;
}

export const isPhpResponse = <T>(res: any): res is PhpResponse<T> => Array.isArray(res?.data);

export const isJavaResponse = <T>(res: any): res is JavaResponse<T> => Array.isArray(res?.content);
