import { Expose, Transform } from 'class-transformer';
import { NotificationRingtone } from './enums/notification-ringtone';

export class BranchShort {
  @Expose({ name: 'externalId' }) id!: string;
  @Expose() name!: string;
}

export class Branch extends BranchShort {
  @Expose() legacyId!: number;
  @Expose() lang!: string;
  @Expose() customerNumber!: string;
  @Expose() disabled!: boolean;
  @Expose() ownerName!: string;
  @Expose() email!: string;
  @Expose() address!: string;
  @Expose() token!: string;
  @Expose() servicePhoneNumber!: string;
  @Expose() currency!: string;
  @Expose() notificationRingtone!: NotificationRingtone;
}

export class DashboardBranch extends BranchShort {
  @Expose() id!: string;
  @Expose() legacyId!: number;
  @Expose() closed!: boolean;
  @Expose({ name: 'reason' }) closedReason!: ClosedReason;
  @Expose() logoUrl!: string;
  @Expose({ name: 'productsStates' }) products!: { [key in BranchProductName]: BranchProduct };

  @Transform(({ value }) => value || 0, { toClassOnly: true })
  @Expose() revenue!: number;

  @Transform(({ value }) => value || 0, { toClassOnly: true })
  @Expose({ name: 'total' }) ordersToday!: number;

  @Transform(({ value }) => value || 0, { toClassOnly: true })
  @Expose({ name: 'unconfirmed' }) unconfirmedOrdersToday!: number;

  @Expose({ name: 'timestamp' }) requestTimestamp!: string;
  @Expose() currency!: string;
}

export enum ClosedReason {
  BRANCH_NOT_EXISTS = 'BRANCH_NOT_EXISTS'
}

export interface BranchProduct {
  enabled: boolean;
  link: string;
}

export enum BranchProductName {
  APP_SHOP = 'APP_SHOP',
  MARKETING_NETWORK = 'MARKETING_NETWORK',
  ORDER_MANAGER = 'ORDER_MANAGER',
  POS = 'POS',
  WEB_SHOP = 'WEB_SHOP'
}
