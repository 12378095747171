import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../loki-desktop/src/environments/environment';
import { tap } from 'rxjs/operators';
import { UserListQuery } from './models/dtos/user-list.query';
import { User } from './models/user';
import { CreateUserDto } from './models/dtos/create-user.dto';
import { UpdateUserDto } from './models/dtos/update-user.dto';
import { JavaResponse } from './models/dtos/api-response.dto';
import { UsersInfo } from './models/dtos/users-info.dto';

@Injectable()
export class UsersDataService {
  private readonly url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = `${environment.api.shopURL}/api/loki/v1/users`;
  }

  public list(query: UserListQuery): Observable<JavaResponse<User>> {
    const params = { ...query.serialize(), page: (query.page - 1).toString() } as any;

    return this.http.get<JavaResponse<User>>(`${this.url}/find`, { params })
      .pipe(
        tap(res => res.pageable.pageNumber++)
      );
  }

  public create(user: CreateUserDto): Observable<User> {
    return this.http.post<User>(`${this.url}`, user);
  }

  public update(id: string, user: UpdateUserDto): Observable<User> {
    return this.http.put<User>(`${this.url}/${id}`, user);
  }

  public delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  }

  public generalInfo(): Observable<UsersInfo> {
    return this.http.get<UsersInfo>(`${this.url}/roles/general`);
  }

  public changePassword(id: string, password: string): Observable<void> {
    return this.http.put<void>(`${this.url}/password/change/${id}`, {}, {
      params: { password },
      responseType: 'text' as any
    });
  }

  public changeStatus(id: string, active: boolean): Observable<User> {
    return this.http.put<User>(active
      ? `${this.url}/activate/${id}` : `${this.url}/deactivate/${id}`, {});
  }
}
