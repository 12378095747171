import { Branch, BranchShort, DashboardBranch } from './models/branch';
import { Observable, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../loki-desktop/src/environments/environment';
import { map, switchMap, tap } from 'rxjs/operators';
import { Mapper } from './models/mapper';
import { PaginatedData } from './models/pagination';
import { JavaQuery, Query } from './models/query';
import { ApiResponse, JavaResponse } from './models/dtos/api-response.dto';
import { OpenBranchResponse, SpecialTimes } from './models/opening-times';
import { OpeningTimesDataService } from './opening-times-data.service';
import { DateTime } from 'luxon';
import { NotificationRingtone } from './models/enums/notification-ringtone';

@Injectable({ providedIn: 'root' })
export class BranchDataService {
  private readonly url: string;

  constructor(
    private http: HttpClient,
    private openingTimesDataService: OpeningTimesDataService
  ) {
    this.url = `${environment.api.gatewayURL}`;
  }

  public list(query?: Query): Observable<PaginatedData<BranchShort>> {
    const params = query && Mapper.mapModel(new JavaQuery(query));
    return this.http.get<ApiResponse<BranchShort>>(`${this.url}/shopsystem/public/api/v1/loki/branches`, { params })
      .pipe(
        map(res => Mapper.mapPaginatedResponse(res, BranchShort))
      );
  }

  public dashboardList(query?: Query): Observable<PaginatedData<DashboardBranch>> {
    const params = query && Mapper.mapModel(new JavaQuery(query));

    let branchApiResponse: JavaResponse<Branch>;
    let branchList: PaginatedData<Branch>;

    return this.http.get<JavaResponse<Branch>>(`${this.url}/shopsystem/public/api/v1/loki/branches`, { params })
      .pipe(
        tap(res => branchApiResponse = res),
        map(res => Mapper.mapPaginatedResponse(res, Branch)),
        tap(mappedBranches => branchList = mappedBranches),
        map(mappedBranches => mappedBranches.data.map(branch => branch.id)),
        switchMap(branchIds => zip(
          this.getOpenState(branchIds),
          this.getBranchProducts(branchIds),
          this.getTodayStatistics(branchList.data.map(branch => branch.legacyId))
        )),
        map(([openStates, products, statistics]) => branchList.data.map(branch => ({
          ...branch,
          ...openStates[branch.id],
          ...products[branch.id],
          ...statistics.data[branch.legacyId],
          timestamp: statistics.timestamp
        }))),
        map(fullBranches => Mapper.mapPaginatedResponse({
          ...branchApiResponse,
          content: fullBranches
        }, DashboardBranch))
      );
  }

  public get(branchId: string): Observable<Branch> {
    return this.http.get<Branch>(`${this.url}/shopsystem/public/api/v1/loki/branches/${branchId}`)
      .pipe(
        map(res => Mapper.mapResponse(res, Branch))
      );
  }

  public requestOpenBranch(branchId: string): Observable<OpenBranchResponse> {
    return this.http.get<OpenBranchResponse>(`${this.url}/openingtimes/public/api/v1/branches/${branchId}/open`)
      .pipe(
        map(res => Mapper.mapResponse(res, OpenBranchResponse))
      );
  }

  public openBranch(branchId: string, data: OpenBranchResponse): Observable<SpecialTimes> {
    const now = DateTime.now().setZone('UTC+1');
    const end = data.openingHours![0].end.split(':');
    const times = [{
      start: now.plus({ minutes: 1 }).set({ second: 0, millisecond: 0 }),
      end: now.set({ hour: +end[0], minute: +end[1], second: 0, millisecond: 0 })
    }];

    const payload = {
      closed: false,
      period: {
        from: now,
        to: now
      },
      openingTimes: {
        pickup: times,
        delivery: times
      }
    };

    return this.openingTimesDataService.createSpecialTimes(branchId, payload as unknown as SpecialTimes);
  }

  public closeBranch(branchId: string): Observable<SpecialTimes> {
    const now = DateTime.now().setZone('UTC+1');
    const times = [{
      start: now.plus({ minutes: 1 }).set({ second: 0, millisecond: 0 }),
      end: now.plus({ minutes: 1 }).set({ second: 0, millisecond: 0 })
    }];

    const payload = {
      closed: true,
      period: {
        from: now,
        to: now
      },
      openingTimes: {
        pickup: times,
        delivery: times
      }
    };

    return this.openingTimesDataService.createSpecialTimes(branchId, payload as unknown as SpecialTimes);
  }

  public saveNotificationRingtone(branchId: string, ringtone: NotificationRingtone): Observable<any> {
    return this.http.post(`${this.url}/shopsystem/public/api/v1/loki/branches/${branchId}/ringtone`, ringtone, {
      responseType: 'text'
    });
  }

  private getOpenState(branchIds: string[]): Observable<any> {
    return this.http.post(`${this.url}/openingtimes/public/api/v1/time-info/branches/statuses`, branchIds);
  }

  private getBranchProducts(branchIds: string[]): Observable<any> {
    return this.http.post(`${this.url}/shopsystem/public/api/v1/loki/branches/states`, { branchIds });
  }

  private getTodayStatistics(legacyBranchIds: number[]): Observable<any> {
    return this.http.post(`${environment.api.phpURL}/api/v2/statistics/orders-today`, { branches: legacyBranchIds });
  }
}
