import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

type Color = 'gray' | 'white';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent<T> {
  @Input() error!: Observable<any>;

  @Input() transparent!: boolean;
  @Input() cover!: boolean;
  @Input() large!: boolean;
  @Input() color: Color = 'gray';
  @Input() text: string = '';
}
