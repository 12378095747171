import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CarouselComponent, SlideChangeParams } from "projects/global-shared/shared/components/carousel/carousel.component";

@Component({
    selector: "app-migration-screens",
    templateUrl: "./migration-screens.component.html",
    styleUrls: ["./migration-screens.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MigrationScreensComponent {
    @ViewChild("carousel") carousel!: CarouselComponent;

    canDoNext = true;
    canDoPrevious = false;
    isLastStep = false;

    constructor(private dialog: MatDialogRef<MigrationScreensComponent>) {}

    onSlideChanged(event: SlideChangeParams) {
        this.canDoNext = event.canDoNext;
        this.canDoPrevious = event.canDoPrevious;
        this.isLastStep = event.isLastStep;
    }

    next(): void {
        this.carousel.next();
    }

    previous(): void {
        this.carousel.previous();
    }

    closeDialog(): void {
        this.dialog.close();
    }
}
