import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

/**
 * @description
 * Keycloak session guard. Actually checks the state
 * of the user authentication when activated
 */
@Injectable()
export class AuthGuard extends KeycloakAuthGuard implements CanActivateChild {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    if (route.data.public) {
      if (!this.authenticated) {
        return true;
      }
      return this.router.parseUrl('/');
    }
    if (this.authenticated) {
      return true;
    }

    return this.keycloak.login({ redirectUri: window.location.origin }) as Promise<any>;
  }
}
