import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Branch } from '../../shared/api/models/branch';
import { BranchService } from '../services/branch.service';
import { AppService } from '../services/app.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class BranchResolver implements Resolve<Branch | undefined> {
  constructor(
    private app: AppService,
    private branch: BranchService,
    private router: Router
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Branch | undefined> {
    const { branchId } = route.params;

    if (!branchId) {
      this.branch.clearBranch();
      return of(undefined);
    }

    return this.app.load(
      this.branch.loadBranch(route.params.branchId)
        .pipe(
          catchError(err => {
            if (err.status !== 404) {
              throw(err);
            }

            this.router.navigate(['not-found'], {
              skipLocationChange: true,
              queryParams: {
                sourceUrl: state.url
              }
            });

            return of(undefined);
          })
        )
    );
  }
}
