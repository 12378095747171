<div class="container">
    <span class="close icon icon-close" (click)="closeDialog()"></span>

    <app-carousel (slideChange)="onSlideChanged($event)" #carousel >
        <app-carousel-item>
            <h1 carouselItemTitle class="title">Enjoy a new and better version!</h1>

            <div carouselItemContent class="content">

                <img class="image step-1" src="assets/img/migration-screens/step-1.webp" />

                <div class="description">
                    <p>
                        We provide a new admin panel that will fully replace shop management system in the future. You
                        will have a transition
                        period to adjust to the new website, while old admin panel will still be available.
                    </p>
                    <p>
                        The transition to a new admin panel is free of extra charge.
                    </p>
                </div>

            </div>
        </app-carousel-item>

        <app-carousel-item>
            <h1 carouselItemTitle class="title">Enjoy a user friendly design.</h1>

            <div carouselItemContent class="content">

                <img class="image step-2" src="assets/img/migration-screens/step-2.webp" />

                <div class="description">
                    <p>
                        We redesigned shop management system for it to become more intuitive and easy to use.
                    </p>
                    <p>
                        Enjoy colourful diagrams and informative tables. </p>
                </div>

            </div>
        </app-carousel-item>

        <app-carousel-item>
            <h1 carouselItemTitle class="title">Use your old login and password.</h1>

            <div carouselItemContent class="content">

                <img class="image step-3" src="assets/img/migration-screens/step-3.webp" />

                <div class="description">
                    <p>
                        Use your existing login and password - no need to register again. Just enter your e-mail and
                        password.
                        If you want to have it auto-filled in the future, select “Remember me”. </p>
                    <p>
                        If you forgot your password, feel free to reset it. </p>
                </div>

            </div>
        </app-carousel-item>

    </app-carousel>



    <div class="actions">
        <button *ngIf="canDoPrevious" (click)="previous()" class="button button-left">Previous</button>
        <button *ngIf="canDoNext" (click)="next()" class="button button-green button-right">Next</button>
        <button *ngIf="isLastStep" (click)="closeDialog()" class="button button-green button-right">Get Started</button>
    </div>
</div>
