import { NgModule } from '@angular/core';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { NormalizeRouteGuard } from './guards/normalize-route.guard';
import { BranchResolver } from './guards/branch.resolver';
import { ResourceGuard } from './guards/resource.guard';
import { RoleGuard } from './guards/role.guard';
import { UserService } from './services/user.service';
import { BranchService } from './services/branch.service';
import { AppService } from './services/app.service';
import { LanguageService } from './services/language.service';
import { ApiModule } from '../shared/api/api.module';
import { KeycloakAngularModule } from 'keycloak-angular';

@NgModule({
  providers: [
    AuthService,
    AppService,
    UserService,
    BranchService,
    LanguageService,
    AuthGuard,
    ResourceGuard,
    RoleGuard,
    NormalizeRouteGuard,
    BranchResolver
  ],
  imports: [
    ApiModule,
    KeycloakAngularModule
  ]
})
export class CoreModule {
}
