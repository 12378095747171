import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { filter, switchMap, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';

/**
 * @description
 * Application resource access management guard
 * Should be attached for branch routes only
 */
@Injectable()
export class ResourceGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private app: AppService,
    private user: UserService
  ) {
  }

  /* Strictly checks resource string from activated route data */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.hasAccess(route.data.resource, route.params.branchId, state.url);
  }

  /**
   * Will check resource string of each navigation destination route
   * Allows navigation if endpoint data not specified
   */
  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const resource = this.endpoint(route).data.resource;

    if (!resource) {
      return true;
    }

    return this.hasAccess(resource, route.params.branchId, state.url);
  }

  /* Retrieves the last activated route of the navigation */
  private endpoint(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    let endpoint = route.firstChild || route;
    while (endpoint.firstChild) {
      endpoint = endpoint.firstChild;
    }
    return endpoint;
  }

  private hasAccess(resource: string, branchId: string, sourceUrl: string): Observable<boolean> {
    return this.app.load(this.user.hasBranch(branchId)
      .pipe(
        tap(hasBranch => !hasBranch && this.router.navigate(['not-found'], {
          skipLocationChange: true,
          queryParams: {
            sourceUrl
          }
        })),
        filter(hasBranch => hasBranch),
        switchMap(() => this.user.hasAccess(resource, branchId)),
        tap(allowed => !allowed && this.router.navigate(['forbidden'], {
          skipLocationChange: true,
          queryParams: {
            sourceUrl
          }
        }))
      ));
  }
}
