import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../loki-desktop/src/environments/environment';

export const initializer = (kc: KeycloakService): () => Promise<void> => () => new Promise(async (res, rej) => {
  try {
    await kc.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId
      },
      loadUserProfileAtStartUp: false,
      initOptions: { onLoad: 'check-sso' },
      bearerExcludedUrls: []
    });
    res();
  } catch (err) {
    rej(err);
  }
});
