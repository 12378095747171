import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../global-shared/core/guards/auth.guard';
import { NormalizeRouteGuard } from '../../../global-shared/core/guards/normalize-route.guard';
import { ErrorComponent } from './error/error.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { public: true },
    loadChildren: () => import('../../../global-shared/public/public.module').then(m => m.PublicModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: 'not-found',
        component: NotFoundComponent
      },
      {
        path: 'crashed',
        component: ErrorComponent
      },
      {
        path: '',
        canActivate: [NormalizeRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
