import { Injectable } from '@angular/core';
import { environment } from '../../../loki-desktop/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OpeningTimes, OpeningTimesSettings, ShopStatus, SpecialTimes, SpecialTimesQuery } from './models/opening-times';
import { Mapper } from './models/mapper';
import { map } from 'rxjs/operators';
import { Period } from './models/filters';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class OpeningTimesDataService {
  private readonly url: string = `${environment.api.gatewayURL}/openingtimes/public/api/v1`;

  constructor(
    private http: HttpClient
  ) {
  }

  public get(branchId: string): Observable<OpeningTimes[]> {
    return this.http.get<OpeningTimes[]>(`${this.url}/opening-hours/branches/${branchId}`)
      .pipe(
        map(res => res.filter(o => o.day < 7)),
        map(res => res.map(o => Mapper.mapResponse(o, OpeningTimes))),
        map(res => res.sort((a, b) => a.day - b.day))
      );
  }

  public save(branchId: string, openingTimes: OpeningTimes[]): Observable<OpeningTimes[]> {
    const payload = openingTimes.map(t => Mapper.mapModel(new OpeningTimes(t)));
    return this.http.put<OpeningTimes[]>(`${this.url}/opening-hours/branches/${branchId}`, payload)
      .pipe(
        map(res => res.filter(o => o.day < 7)),
        map(res => res.map(o => Mapper.mapResponse(o, OpeningTimes))),
        map(res => res.sort((a, b) => a.day - b.day))
      );
  }

  public getSettings(branchId: string): Observable<OpeningTimesSettings> {
    return this.http.get<OpeningTimesSettings>(`${this.url}/time-info/branches/${branchId}`)
      .pipe(
        map(res => Mapper.mapResponse(res, OpeningTimesSettings))
      );
  }

  public patchSetting(branchId: string, settings: OpeningTimesSettings): Observable<OpeningTimesSettings> {
    const payload = Mapper.mapModel(new OpeningTimesSettings(settings));
    return this.http.put<OpeningTimesSettings>(`${this.url}/time-info/branches/${branchId}`, payload)
      .pipe(
        map(res => Mapper.mapResponse(res, OpeningTimesSettings))
      );
  }

  public listSpecialTimes(branchId: string, query?: SpecialTimesQuery): Observable<SpecialTimes[]> {
    const params = query && Mapper.mapModel(new SpecialTimesQuery(query));
    return this.http.get<SpecialTimes[]>(`${this.url}/special-opening-hours/branches/${branchId}`, { params })
      .pipe(
        map(res => res.map(s => Mapper.mapResponse(s, SpecialTimes)))
      );
  }

  public createSpecialTimes(branchId: string, specialTimes: SpecialTimes): Observable<SpecialTimes> {
    const payload = Mapper.mapModel(new SpecialTimes(specialTimes));
    return this.http.post<SpecialTimes>(`${this.url}/special-opening-hours/branches/${branchId}`, payload)
      .pipe(
        map(res => Mapper.mapResponse(res, SpecialTimes))
      );
  }

  public patchSpecialTimes(branchId: string, specialTimes: SpecialTimes): Observable<SpecialTimes> {
    const payload = Mapper.mapModel(new SpecialTimes(specialTimes));
    return this.http.put<SpecialTimes>(`${this.url}/special-opening-hours/branches/${branchId}`, payload)
      .pipe(
        map(res => Mapper.mapResponse(res, SpecialTimes))
      );
  }

  public deleteSpecialTimes(branchId: string, specialTimes: Pick<SpecialTimes, 'period'>): Observable<void> {
    const payload = { body: Mapper.mapModel(new SpecialTimes(specialTimes)) } as any;
    return this.http.delete<void>(`${this.url}/special-opening-hours/branches/${branchId}`, payload) as any;
  }

  public getSpecialTimesYears(branchId: string, query?: Pick<SpecialTimesQuery, 'closed'>): Observable<Period[]> {
    const params = query as any;
    return this.http.get<number[]>(`${this.url}/special-opening-hours/branches/${branchId}/year`, { params })
      .pipe(
        map(res => res
          .sort((a, b) => b - a)
          .map(y => ({
            from: DateTime.fromObject({ year: y }).startOf('year'),
            to: DateTime.fromObject({ year: y }).endOf('year')
          }))
        )
      );
  }

  public getStatus(branchId: string): Observable<ShopStatus> {
    return this.http.get<ShopStatus>(`${this.url}/time-info/branches/${branchId}/status`);
  }
}
