import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StatisticBranch, StatisticBranchDynamic, StatisticDto } from './models/dtos/statistic.dto';
import { environment } from '../../../loki-desktop/src/environments/environment';
import { tap } from 'rxjs/operators';
import { Platform } from './models/enums/platform.enum';
import { StatisticQuery } from './models/dtos/statistic.query';
import { StatisticBranchQuery } from './models/dtos/statistic-branch.query';
import { JavaResponse } from './models/dtos/api-response.dto';
import { DaytimeStatisticDto } from './models/dtos/daytime-statistic.dto';

@Injectable()
export class StatisticDataService {
  private readonly url: string;

  constructor(
    private http: HttpClient
  ) {
    this.url = `${environment.api.gatewayURL}/stat/public/api/v1`;
  }

  public searchBranches(search: string): Observable<StatisticBranch[]> {
    return this.http.get<StatisticBranch[]>(`${this.url}/branches`, { params: { search } });
  }

  public get(query: StatisticQuery): Observable<Partial<StatisticDto>> {
    return this.http.get<Partial<StatisticDto>>(`${this.url}/stat/orders`, { params: query.serialize() as any })
      .pipe(
        tap(stats => {
          Object.values(stats.recentByDay || {}).forEach(v => {
            delete v.revenueTotalBeforeYesterday;
            delete v.revenueTotalYesterday;
            delete v.revenueTotalYesterdayWeekAgo;
          });
        }),
        tap(stats => {
          Object.values(stats.total || {}).forEach(v => v.revenueTotal /= 100);
          Object.values(stats.averageRevenue || {}).forEach(v => v.revenuePerOrder /= 100);
          Object.values(stats.lastMonths || {}).forEach(
            p => Object.values(p).forEach(v => {
              v.revenueTotal /= 100;
              v.revenuePerOrder /= 100;
            })
          );
        })
      );
  }

  public topBranches(from?: string, to?: string, platform?: Platform): Observable<StatisticBranchDynamic[]> {
    const params: any = { orderSourceType: platform || Platform.TOTAL };
    from && (params.from = from);
    to && (params.to = to);

    return this.http.get<StatisticBranchDynamic[]>(`${this.url}/stat/branches/top`, { params })
      .pipe(
        tap(res => res.forEach(r => {
          r.revenueTotal /= 100;
          r.revenueTotalPrev /= 100;
          r.revenueChange /= 100;
          r.dynamic === null && (r.dynamic = 100);
        }))
      );
  }

  public branches(query: StatisticBranchQuery): Observable<JavaResponse<StatisticBranchDynamic>> {
    const params = { ...query.serialize(), page: (query.page - 1).toString() } as any;

    return this.http.get<JavaResponse<StatisticBranchDynamic>>(`${this.url}/stat/branches`, { params })
      .pipe(
        tap(res => res.pageable.pageNumber++),
        tap(res => res.content.forEach(r => {
          r.revenueTotal /= 100;
          r.revenueTotalPrev /= 100;
          r.revenueChange /= 100;
          r.dynamic === null && (r.dynamic = 100);
        }))
      );
  }

  public daytimeSales(branchId: string, query: StatisticQuery): Observable<DaytimeStatisticDto[]> {
    return this.http.get<DaytimeStatisticDto[]>(`${this.url}/stat/branches/${branchId}/daytime-sales`, { params: query.serialize() as any });
  }
}
