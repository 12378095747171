import { AfterContentChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../global-shared/core/services/language.service';
import { AppService } from '../../../global-shared/core/services/app.service';
import { GoogleAnalyticsService } from "projects/global-shared/shared/modules/google-analytics/google-analytics.service";
import { environment } from '../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MigrationScreensComponent } from './shared/migration-screens/migration-screens.component';
import { UserService } from 'projects/global-shared/core/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Dispose } from 'projects/global-shared/shared/components/dispose';

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent extends Dispose implements OnInit {
    constructor(
        public app: AppService,
        private languageService: LanguageService,
        private analyticsService: GoogleAnalyticsService,
        private userService: UserService,
        private dialog: MatDialog
    ) {
        super();
    }

    public ngOnInit(): void {
        this.languageService.setBrowserLang();
        this.analyticsService.setupAnalytics(environment);

        this.showMigrationDialog();
    }

    public showMigrationDialog(): void {

        this.userService.current$.pipe(takeUntil(this.destroy$)).subscribe((userInfo) => {

            if (!userInfo.lastLoginAt) {
                this.dialog.open(MigrationScreensComponent, {
                    panelClass: "dialog",
                });
            }
        });

    }
}
