import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BranchService } from '../../core/services/branch.service';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../loki-desktop/src/environments/environment';

@Injectable()
export class LegacyIdInterceptor implements HttpInterceptor {
  constructor(
    private branch: BranchService
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.branch.current$
      .pipe(
        take(1),
        switchMap(branch => {
          if (req.url.startsWith(environment.api.phpURL) && branch && req.url.includes(branch.id)) {
            req = req.clone({ url: req.url.replace(branch.id, branch.legacyId.toString()) });
          }
          return next.handle(req);
        })
      );
  }
}
