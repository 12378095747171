import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Output,
    QueryList,
    Renderer2,
} from "@angular/core";
import { CarouselItemComponent } from "./carousel-item/carousel-item.component";

export interface SlideChangeParams {
    canDoNext: boolean;
    canDoPrevious: boolean;
    isLastStep: boolean;
    slideIndex: number;
}

@Component({
    selector: "app-carousel",
    templateUrl: "./carousel.component.html",
    styleUrls: ["./carousel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterContentChecked {
    @ContentChildren(CarouselItemComponent, { read: ElementRef }) items: QueryList<ElementRef> | undefined;

    @Output() slideChange = new EventEmitter<SlideChangeParams>();

    currentSlide = 0;

    public get canDoNext() {
        return this.currentSlide + 1 < this.items!.length;
    }

    public get canDoPrevious() {
        return this.currentSlide - 1 >= 0;
    }

    public get isLastStep() {
        return this.currentSlide + 1 === this.items?.length;
    }

    constructor(private renderer: Renderer2) {}

    ngAfterContentChecked(): void {
        this.showSlide(this.currentSlide);
    }

    public next(): void {
        if (!this.canDoNext) {
            return;
        }

        this.currentSlide++;
        this.showSlide(this.currentSlide);
    }

    public previous(): void {
        if (!this.canDoPrevious) {
            return;
        }

        this.currentSlide--;
        this.showSlide(this.currentSlide);
    }

    private showSlide(index: number) {
        this.items?.forEach((item, i) => {
            if (index === i) {
                this.renderer.setStyle(item.nativeElement, "display", "block");

                this.slideChange.emit({
                    slideIndex: index,
                    canDoNext: this.canDoNext,
                    canDoPrevious: this.canDoPrevious,
                    isLastStep: this.isLastStep,
                });
            } else {
                this.renderer.setStyle(item.nativeElement, "display", "none");
            }
        });
    }
}
