import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class NotificationService {
  constructor(
    private router: Router
  ) {
  }

  public openErrorPage(error: any): void {
    this.router.navigate(['/crashed'], { skipLocationChange: true });
  }

  public notifyError(error: any): void {
    // todo: notify user according the error type
  }
}
