import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { User } from '../../shared/api/models/user';
import { map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { UserDataService } from '../../shared/api/user-data.service';
import { LanguageService } from './language.service';
import { Lang } from '../../shared/api/models/enums/lang.enum';
import { BranchService } from './branch.service';
import { Role } from 'projects/global-shared/shared/api/models/enums/role.enum';

@Injectable()
export class UserService {
  private userSrc$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);

  private user$?: Observable<User>;

  public get current$(): Observable<User> {
    if (!this.user$) {
      this.user$ = this.loadUser()
        .pipe(
          switchMap(() => this.userSrc$ as BehaviorSubject<User>),
          shareReplay(1)
        );
    }

    return this.user$;
  }

  public get role$(): Observable<string> {
    return this.current$
      .pipe(
        withLatestFrom(this.branch.current$),
        map(([user, branch]) =>
          user.resources.find(r => r.branchId === branch?.id)?.role!
        )
      )
  }

  constructor(
    private userData: UserDataService,
    private branch: BranchService,
    private language: LanguageService
  ) {
  }

  public changeLanguage(language: Lang): Observable<any> {
    const user = this.userSrc$.value;
    if (!user) {
      throw new Error('user not loaded!');
    }

    if (user.profile.language === language) {
      return of();
    }
    
    return this.userData.changeProfile(user.externalId, { ...user.profile, language })
      .pipe(
        map(() => ({
          ...user,
          profile: { ...user.profile, language }
        })),
        tap((updatedUser: User) => this.userSrc$.next(updatedUser)),
        switchMap((updatedUser: User) => this.language.setLang(updatedUser.profile.language))
      );
  }

  public hasAccess(resource: string, branchId: string): Observable<boolean> {
    return this.current$
      .pipe(
        map(user => {
          const all = user.resources?.find(r => r.branchId === 'ALL');
          if (all?.resources.includes(resource)) {
            return true;
          }

          return !!user.resources?.find(r => r.branchId === branchId)?.resources.includes(resource);
        }),
        take(1)
      );
  }

  public hasRole(role: string): Observable<boolean> {
    return this.current$
      .pipe(
        withLatestFrom(this.branch.current$),
        map(([user, branch]) =>
          user.resources.find(r => r.branchId === branch?.id || r.branchId === 'ALL')?.role === role
        ),
        map(role => !!role),
        take(1)
      );
  }

  public hasBranch(branchId: string): Observable<boolean> {
    return this.current$
      .pipe(
        map(user => user.resources?.some(r => r.branchId === branchId || r.branchId === 'ALL')),
        take(1)
      );
  }

  public currentRole(): Observable<Role | undefined> {
    return this.current$
      .pipe(
        withLatestFrom(this.branch.current$),
        map(([user, branch]) =>
          user.resources.find(r => r.branchId === branch?.id || r.branchId === 'ALL')?.role as Role | undefined
        ),
        take(1)
      );
  }

  private loadUser(): Observable<User> {
    return forkJoin([
      this.userData.profile(),
      this.userData.resources()
    ])
      .pipe(
        map(([profile, resources]) => {
          profile.resources = resources;
          return profile;
        }),
        tap(user => this.language.setLang(user.profile.language)),
        tap(user => this.userSrc$.next(user))
      );
  }
}
