// TODO: Get rid of outdated roles.
export enum Role {
  // Outdated roles.
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SUPPORT = 'ROLE_SUPPORT',
  ROLE_LOW_LEVEL_SUPPORT = 'ROLE_LOW_LEVEL_SUPPORT',
  ROLE_ACCOUNTANT = 'ROLE_ACCOUNTANT',
  ROLE_SALESMAN = 'ROLE_SALESMAN',
  // New roles.
  ADMIN = 'ADMIN',
  ACCOUNTANT = 'ACCOUNTANT'
}
