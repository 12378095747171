import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MigrationScreensComponent } from "./migration-screens.component";
import { CarouselModule } from "projects/global-shared/shared/components/carousel/carousel.module";

@NgModule({
    declarations: [MigrationScreensComponent],
    imports: [CommonModule, CarouselModule],
    exports: [MigrationScreensComponent],
})
export class MigrationScreensModule {}
