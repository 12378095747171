import { Injectable } from '@angular/core';
import { Branch } from '../../shared/api/models/branch';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { BranchDataService } from '../../shared/api/branch-data.service';
import { Router } from '@angular/router';

@Injectable()
export class BranchService {
  /**
   * Current restaurant branch data.
   * Can be empty at non branch resource pages
   */
  public current$: Observable<Branch | undefined>;

  private branchSrc$: BehaviorSubject<Branch | undefined> = new BehaviorSubject<Branch | undefined>(undefined);

  public get current(): Branch | undefined {
    return this.branchSrc$.value;
  }

  constructor(
    private router: Router,
    private branchData: BranchDataService
  ) {
    this.current$ = this.branchSrc$.asObservable();
  }

  public selectBranch(branchId: string): void {
    if (this.branchSrc$.value?.id !== branchId) {
      this.router.navigate([`/${branchId}/dashboard`]);
    }
  }

  /**
   * Load & apply required branch settings.
   * Intended to be used by data resolvers
   */
  public loadBranch(branchId: string): Observable<Branch> {
    if (this.branchSrc$.value?.id !== branchId) {
      return this.branchData.get(branchId)
        .pipe(
          tap(branch => this.branchSrc$.next(branch))
        );
    }

    return (this.current$ as Observable<Branch>)
      .pipe(take(1));
  }

  public adjustBranch(branch: Partial<Branch>): void {
    if (!this.current) {
      return;
    }

    this.branchSrc$.next({
      ...this.current,
      ...branch
    });
  }

  public clearBranch(): void {
    this.branchSrc$.next(undefined);
  }
}
