import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadingComponent } from '../../shared/components/loading.component';

@Injectable()
export class AppService extends LoadingComponent {
  constructor(
    private router: Router
  ) {
    super();
  }

  public load<T>(observable: Observable<T>): Observable<T> {
    return super.load(observable)
      .pipe(
        catchError(err => {
          setTimeout(() => this.router.navigate(['/crashed'], { skipLocationChange: true }));
          return throwError(err);
        })
      );
  }
}
