import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Dispose } from './dispose';

// todo: rename
export abstract class LoadingComponent extends Dispose {
  public error$: Observable<any>;
  public loading$: Observable<boolean>;

  protected errorSrc$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  protected loadingSrc$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private loaderBox: LoaderBox = new LoaderBox();

  constructor() {
    super();

    this.error$ = this.errorSrc$.asObservable();
    this.loading$ = this.loadingSrc$.asObservable();
  }

  protected load<T>(observable: Observable<T>): Observable<T> {
    return of(EMPTY)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.next(true)),
        switchMap(() => observable),
        finalize(() => this.next(false)),
        catchError(err => {
          this.errorSrc$.next(err);
          return throwError(err);
        })
      );
  }

  protected next(loading: boolean): void {
    loading && this.errorSrc$.next(undefined);
    loading ? this.loaderBox.load() : this.loaderBox.complete();
    this.loadingSrc$.next(this.loaderBox.loading);
  }
}

class LoaderBox {
  private processes: number = 0;

  public get loading(): boolean {
    return this.processes > 0;
  }

  public load(): void {
    this.processes++;
  }

  public complete(): void {
    this.processes--;
  }
}
