import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { NotificationService } from '../../../global-shared/shared/services/notification/notification.service';

@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(
    private notification: NotificationService,
    private ngZone: NgZone
  ) {
    super();
  }

  public handleError(error: any): void {
    // see zonejs UncaughtPromiseError interface
    if (error.promise && error.rejection) {
      error = error.rejection;
    }

    this.ngZone.run(() => this.notification.notifyError(error));

    super.handleError(error);
  }
}
