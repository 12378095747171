import { Exclude, Expose, Transform } from 'class-transformer';
import { Mapper } from './mapper';
import { Period, Sort, SortOrder } from './filters';

// todo: move into separate file after legacy `Model` class will be removed
/** Basic constructor for mapper classes */
export class Model {
  constructor(model: Model) {
    Object.assign(this, model);
  }
}

/**
 * List query mapper class.
 *
 * @description
 * Only for web layer usage.
 * Don't import any mapper models in business code
 *
 * @private
 */
export abstract class Query extends Model {
  /**
   * Two dates interval representation.
   * Will disappear from the payload before api request
   */
  @Exclude() period?: Period;

  /**
   * Hidden class field representing the `from` query param.
   * Reads the value from `period` property and transforms it to ISO string
   */
  @Expose()
  @Transform(p => p.value = p.obj.period?.from)
  @Transform(Mapper.dateTransform)
  private from?: string;

  /**
   * Hidden class field representing the `to` query param.
   * Reads the value from `period` property and transforms it to ISO string
   */
  @Expose()
  @Transform(p => p.value = p.obj.period?.to)
  @Transform(Mapper.dateTransform)
  private to?: string;

  /** List sorting options */
  @Expose() sort?: Sort;

  /** Search query string */
  @Expose() search?: string;

  // Pagination parameters
  @Expose() page?: number;
  @Expose() size?: number;
}

/** List query model for php api requests */
export class PhpQuery extends Query {
  /**
   * Hidden class field representing the `direction` query param.
   * Picks sort order option from `sort` property object
   */
  @Expose()
  @Transform(p => p.value = p.obj.sort?.order)
  private direction?: SortOrder;

  /** Transforms `sort` object into a sort key string */
  @Expose()
  @Transform(p => p.value?.key)
  public sort?: Sort;

  @Expose({ name: 'per_page' }) size?: number;
}

/** List query model for java api requests */
export class JavaQuery extends Query {
  /** Transforms `sort` object into a formatted string */
  @Expose()
  @Transform(p => p.value && `${p.value.key},${p.value.order}`)
  public sort?: Sort;

  /**
   * Decrements page number according to `Spring` pagination format
   * which starts page counting at `0`
   */
  @Expose()
  @Transform(p => p.value && --p.value)
  public page?: number;
}
